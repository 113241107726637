import '../../organisms/image-carousel/ImageCarousel';
/* eslint-disable import/no-duplicates */
import '../../organisms/image-viewer/ImageViewer';
import { ImageViewerEvents } from '../../organisms/image-viewer/ImageViewer';
/* eslint-enable import/no-duplicates */
import type { DefaultComponentType } from '../../../../src/utils/types';
import type ImageViewer from '../../organisms/image-viewer/ImageViewer';
import type ImageCarousel from '../../organisms/image-carousel/ImageCarousel';
import type Image from '../../atoms/image/Image';
import type { ImageProps } from '../../atoms/image/Image';
import './ImageCarouselViewer.pcss';

export type ImageCarouselViewerProps = DefaultComponentType & {
    slides: ImageProps[];
    thumbnails?: ImageProps[];
    fullSizeSlides?: ImageProps[];
    loadNextImage?: boolean;
    leftBottomSlot?: string;
};

export enum ImageCarouselViewerEvent {
    IMAGE_CLICK = 'image-carousel-image-click-event'
}

export default class ImageCarouselViewer extends HTMLElement {
    readonly #carousel: ImageCarousel;

    get carousel(): ImageCarousel {
        return this.#carousel;
    }

    readonly #images: NodeListOf<Image> | [] = [];

    readonly #viewer: ImageViewer;

    get viewer(): ImageViewer {
        return this.#viewer;
    }

    constructor() {
        super();

        this.#carousel = this.querySelector('nh-image-carousel') as ImageCarousel;
        this.#images = this.#carousel?.querySelectorAll('[data-role="image-slide"]');
        this.#viewer = this.querySelector('nh-image-viewer') as ImageViewer;
    }

    protected connectedCallback(): void {
        if (this.#carousel === null || this.#viewer === null) {
            return;
        }

        this.addEventListener(
            ImageViewerEvents.SELECTED_INDICATOR,
            this.#handleSelectedIndicatorEvent
        );

        if (this.#images.length === 0) {
            return;
        }

        this.#images.forEach((image) => image.addEventListener('click', this.#handleClickEvent));
    }

    protected disconnectedCallback(): void {
        if (this.#carousel === null || this.#viewer === null) {
            return;
        }

        this.removeEventListener(
            ImageViewerEvents.SELECTED_INDICATOR,
            this.#handleSelectedIndicatorEvent
        );

        if (this.#images.length === 0) {
            return;
        }

        this.#images.forEach((image) => image.removeEventListener('click', this.#handleClickEvent));
    }

    #handleClickEvent = (): void => {
        this.#viewer.showModal(this.#carousel.current);
        this.dispatchEvent(
            new CustomEvent(ImageCarouselViewerEvent.IMAGE_CLICK, {
                detail: {
                    index: this.#carousel.current
                }
            })
        );
    };

    #handleSelectedIndicatorEvent = (event: Event): void => {
        if (!(event instanceof CustomEvent)) {
            return;
        }

        this.#carousel.changeHandler(event, event.detail, false);
    };
}

if (!window.customElements.get('nh-image-carousel-viewer')) {
    window.customElements.define('nh-image-carousel-viewer', ImageCarouselViewer);
}
