var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/date-from-to/DateFromTo.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/atoms/date-from-to/DateFromTo.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("iconTemplate", t_1);
var t_4;
t_4 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "start"),"") != "";
frame.set("hasStartDate", t_4, true);
if(frame.topLevel) {
context.setVariable("hasStartDate", t_4);
}
if(frame.topLevel) {
context.addExport("hasStartDate", t_4);
}
var t_5;
t_5 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "end"),"") != "";
frame.set("hasEndDate", t_5, true);
if(frame.topLevel) {
context.setVariable("hasEndDate", t_5);
}
if(frame.topLevel) {
context.addExport("hasEndDate", t_5);
}
output += "<span part=\"start-placeholder\"";
if(runtime.contextOrFrameLookup(context, frame, "hasStartDate")) {
output += "hidden";
;
}
output += ">";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "startPlaceholder"),""), env.opts.autoescape);
output += "</span>\n<span part=\"start-value\"";
if(!runtime.contextOrFrameLookup(context, frame, "hasStartDate")) {
output += "hidden";
;
}
output += ">";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "start"),""), env.opts.autoescape);
output += "</span>\n\n";
output += runtime.suppressValue((lineno = 8, colno = 22, runtime.callWrap(runtime.memberLookup((t_1),"render"), "iconTemplate[\"render\"]", context, [{"name": "direction-right","size": 0.625,"custom": true,"data": {"part": "divider"}}])), env.opts.autoescape);
output += "\n\n<span part=\"end-placeholder\"";
if(runtime.contextOrFrameLookup(context, frame, "hasEndDate")) {
output += "hidden";
;
}
output += ">";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "endPlaceholder"),""), env.opts.autoescape);
output += "</span>\n<span part=\"end-value\"";
if(!runtime.contextOrFrameLookup(context, frame, "hasEndDate")) {
output += "hidden";
;
}
output += ">";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "end"),""), env.opts.autoescape);
output += "</span>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/date-from-to/DateFromTo.html.njk"] , dependencies)