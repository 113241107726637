import LoaderComponent from '@naturehouse/design-system/components/atoms/loader/Loader.create';
import ListComponent, {
    Orientation
} from '@naturehouse/design-system/components/molecules/list/List.create';

export class SustainabilityLoaderRenderer {
    public static createLoader(): string {
        return LoaderComponent({
            width: 275,
            height: 24,
            rows: 1,
            animation: true
        });
    }

    public generateSkeletonLoaderHtml(count: number): string {
        const loaderHTML = SustainabilityLoaderRenderer.createLoader();
        const items = Array.from({ length: count }, () => ({ label: loaderHTML }));

        return ListComponent({
            orientation: Orientation.VERTICAL,
            items: items
        });
    }
}

export default new SustainabilityLoaderRenderer();
