import AbstractSubject, {
    Observer
} from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import Modal from '../modal/Modal';

export default class TravelPartyModalHandler extends AbstractSubject {
    readonly #modal: Modal;

    readonly #inputs: NodeListOf<HTMLInputElement>;

    readonly #doneButton: Element | null;

    get #values(): Record<string, number> {
        const values: Record<string, number> = {};
        this.#inputs.forEach((input): void => {
            const name = input.name as string;
            values[name] = Number(input.value);
        });
        return values;
    }

    constructor(modal: Modal) {
        super();

        this.#modal = modal;
        this.#inputs = modal.querySelectorAll('input[type="number"]');
        this.#doneButton = this.#modal.footer.querySelector('[data-role="close-modal"]');

        this.#addEventListeners();
    }

    attach(observer: Observer): void {
        super.attach(observer);
        const event = new CustomEvent('change', { detail: this.#values });
        observer.update(event);
    }

    #addEventListeners(): void {
        this.#doneButton?.addEventListener('click', this.#closeModal.bind(this));
        this.#inputs.forEach((input): void => {
            input.addEventListener('change', this.#handleChange.bind(this));
        });
    }

    #handleChange(): void {
        const event = new CustomEvent('change', { detail: this.#values });
        this.notify(event);
    }

    #closeModal(): void {
        if (!this.#modal.isOpen) return;
        this.#modal.close();
    }
}
