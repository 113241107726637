import paginationTemplate from '@naturehouse/design-system/components/molecules/pagination/Pagination.html.njk';
import AbstractSubject, {
    Observer,
    Subject
} from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import calculatePagination from '../../../../util/pagination';
import ReviewListItems from './ReviewListItems';

export default class ReviewPaginationRenderer extends AbstractSubject implements Observer {
    static #instance: ReviewPaginationRenderer | null = null;

    public static getInstance(): ReviewPaginationRenderer {
        if (!ReviewPaginationRenderer.#instance) {
            ReviewPaginationRenderer.#instance = new ReviewPaginationRenderer(
                ReviewListItems.getInstance(),
                document.getElementById('reviews-curtain')
            );
        }

        return ReviewPaginationRenderer.#instance;
    }

    public static destroyInstance(): void {
        ReviewPaginationRenderer.#instance = null;
    }

    readonly #reviewListItems: ReviewListItems;

    readonly #parentElement: HTMLElement | null = null;

    readonly #paginationElement: HTMLElement = document.createElement('nav');

    get paginationElement(): HTMLElement {
        return this.#paginationElement;
    }

    readonly #translations: Record<string, string>;

    public constructor(reviewListItems: ReviewListItems, parentElement: HTMLElement | null) {
        super();

        this.#reviewListItems = reviewListItems;
        this.#parentElement = parentElement;
        this.#translations = JSON.parse(this.#paginationElement?.dataset.translations || '{}');

        this.#reviewListItems.attach(this);

        if (!this.#parentElement) {
            return;
        }

        this.#parentElement.appendChild(this.#paginationElement);
    }

    public update(subject: Subject): void {
        if (!(subject instanceof ReviewListItems) || !this.#parentElement) {
            return;
        }

        if (this.#reviewListItems.total === 0) {
            this.#paginationElement.innerHTML = '';
            return;
        }

        this.#paginationElement.innerHTML = this.#createTemplate().innerHTML;
        this.notify();
    }

    #createTemplate(): HTMLElement {
        const url: URL = new URL(window.location.href);

        const pagination = calculatePagination(
            this.#reviewListItems.skip,
            this.#reviewListItems.limit,
            this.#reviewListItems.total,
            (skip: number, limit: number) => {
                url.searchParams.set('skip', skip.toString());
                url.searchParams.set('limit', limit.toString());
                return url.href;
            },
            false
        );

        const pages = Array.from(pagination.pages as Map<string, string>).map((page) => ({
            label: page[0],
            href: page[1]
        }));

        const html = paginationTemplate.render({
            labels: {
                of: this.#translations.of
            },
            current: pagination.current,
            total: pagination.total,
            pages: pages,
            previous: {
                label: this.#translations.previous,
                href: pagination.prev
            },
            next: {
                label: this.#translations.next,
                href: pagination.next
            },
            first: {
                label: '1',
                href: pagination.first
            },
            last: {
                label: pagination.total,
                href: pagination.last
            },
            data: {
                'data-role': this.#paginationElement?.dataset.role,
                'data-cy': this.#paginationElement?.dataset.cy,
                'data-translations': this.#paginationElement?.dataset.translations
            }
        });

        return parseStringAsHtml(html, 'nav');
    }
}
