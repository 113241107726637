import TranslatableContent from './TranslatableContent';
import TranslatableContentSwitch, {
    TranslatableContentSwitchEvent
} from './TranslatableContentSwitch';

export default class TranslationFetcher extends HTMLElement {
    #contentElements = this.querySelectorAll<TranslatableContent>('nh-translatable-content');

    #contentSwitch = this.querySelector(
        'nh-translatable-content-switch'
    ) as TranslatableContentSwitch;

    public connectedCallback(): void {
        this.#contentSwitch = this.querySelector(
            'nh-translatable-content-switch'
        ) as TranslatableContentSwitch;

        this.#contentElements =
            this.querySelectorAll<TranslatableContent>('nh-translatable-content');

        if (!this.#contentSwitch) {
            return;
        }

        if (this.#contentElements.length <= 0) {
            throw new Error('Missing one or multiple required nh-translatable-content components!');
        }

        this.#contentSwitch.addEventListener(
            TranslatableContentSwitchEvent.CURRENT,
            this.#showCurrent
        );
        this.#contentSwitch.addEventListener(
            TranslatableContentSwitchEvent.SOURCE,
            this.#showSource
        );
    }

    public disconnectedCallback(): void {
        this.#contentSwitch?.removeEventListener(
            TranslatableContentSwitchEvent.CURRENT,
            this.#showCurrent
        );
        this.#contentSwitch?.removeEventListener(
            TranslatableContentSwitchEvent.SOURCE,
            this.#showSource
        );
    }

    readonly #showCurrent = (): void => {
        this.#contentElements.forEach(async (element): Promise<void> => {
            await element.showCurrent();
        });
    };

    readonly #showSource = (): void => {
        this.#contentElements.forEach(async (element): Promise<void> => {
            await element.showSource();
        });
    };
}

if (!customElements.get('nh-translation-fetcher')) {
    customElements.define('nh-translation-fetcher', TranslationFetcher);
}
