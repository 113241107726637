import AbstractSubject, {
    Observer
} from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import { CollectionEvents } from '../../../../common/CollectionBase';
import ReviewTranslationCollection, {
    ReviewTranslationCollectionResponse
} from './ReviewTranslationCollection';
import { TranslatableContentResponse, TranslationStorageType } from './TranslatableContent';
import { iTranslationStorage } from './iTranslationStorage';

export default class ReviewCollectionStorage
    extends AbstractSubject
    implements Observer, iTranslationStorage
{
    static #instance: ReviewCollectionStorage | null = null;

    public static getInstance(): ReviewCollectionStorage {
        if (this.#instance === null) {
            this.#instance = new ReviewCollectionStorage();
        }

        return this.#instance;
    }

    public static destroyInstance(): void {
        this.#instance = null;
    }

    readonly #collection: ReviewTranslationCollection = ReviewTranslationCollection.getInstance();

    #translationData: TranslatableContentResponse[] | null = null;

    get translationData(): TranslatableContentResponse[] | null {
        return this.#translationData;
    }

    private constructor() {
        super();
        this.#collection.attach(this);
    }

    public update(subject: CustomEvent): void {
        if (subject.type !== CollectionEvents.ITEM_RETRIEVED) {
            return;
        }

        const data = subject.detail.data as ReviewTranslationCollectionResponse;
        const houseData = {
            ...data.house,
            type: TranslationStorageType.REVIEW_HOUSE_COMMENT
        };

        const natureData = {
            ...data.nature,
            type: TranslationStorageType.REVIEW_NATURE_COMMENT
        };
        this.#translationData = [houseData, natureData];
        this.notify();
    }
}
