import AbstractSubject from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import ClientStorage from '@naturehouse/nh-essentials/lib/storage/Storage';
import AbstractStorageStrategy from '@naturehouse/nh-essentials/lib/storage/strategies/AbstractStorageStrategy';
import MemoryStorageStrategy from '@naturehouse/nh-essentials/lib/storage/strategies/MemoryStorageStrategy';
import { CollectionEvents } from '../../../../../common/CollectionBase';
import ArrivalDatesCollection, {
    ArrivalDatesCollectionEndpointParams
} from './ArrivalDatesCollection';

export enum ArrivalDatesStorageType {
    ARRIVAL_DATES = 'arrival_dates'
}

export enum ArrivalDatesStorageManagerEvents {
    COLLECTION_UPDATED = 'collection-updated'
}

export default class ArrivalDatesStorageManager extends AbstractSubject {
    static #instance: ArrivalDatesStorageManager | null = null;

    public static getInstance(): ArrivalDatesStorageManager {
        if (this.#instance === null) {
            this.#instance = new ArrivalDatesStorageManager();
        }

        return this.#instance;
    }

    readonly #storageKey: ArrivalDatesStorageType = ArrivalDatesStorageType.ARRIVAL_DATES;

    readonly #arrivalDatesCollection: ArrivalDatesCollection = ArrivalDatesCollection.getInstance();

    private constructor() {
        super();

        this.#arrivalDatesCollection.attach(this);
    }

    public async update(event: CustomEvent): Promise<void> {
        if (event.type !== CollectionEvents.ITEM_RETRIEVED) {
            return;
        }

        await this.#handleRetrieved(event.detail.data);
    }

    public async fetchInitialData(params: ArrivalDatesCollectionEndpointParams): Promise<void> {
        const storage = await this.#getStorage();

        const arrivalDates = await this.#arrivalDatesCollection.retrieve(params);
        await storage.setItem(this.#storageKey, arrivalDates);
    }

    async #getStorage(): Promise<AbstractStorageStrategy<string[]>> {
        return ClientStorage.retrieveStorage<string[]>('ArrivalDatesStore', MemoryStorageStrategy);
    }

    async #handleRetrieved(data: string[]): Promise<void> {
        const storage = await this.#getStorage();

        const value = (await storage.getItem(this.#storageKey)) || [];
        const mergedData = [...value, ...data];
        const result = await storage.updateItem(this.#storageKey, [...new Set(mergedData)]);

        const event: CustomEvent = new CustomEvent(
            ArrivalDatesStorageManagerEvents.COLLECTION_UPDATED,
            {
                detail: {
                    type: this.constructor.name,
                    data: result
                }
            }
        );

        this.notify(event);
    }
}
