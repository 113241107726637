import UnavailableMethodException from '@naturehouse/nh-essentials/lib/exceptions/UnavailableMethodException';
import HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';
import CollectionBase from '../../../../common/CollectionBase';
import { TranslatableCollectionData } from './TranslatableContent';

export default class DescriptionCollection extends CollectionBase<TranslatableCollectionData> {
    static #instance: DescriptionCollection | null = null;

    public static getInstance(): DescriptionCollection {
        if (this.#instance === null) {
            this.#instance = new DescriptionCollection();
        }

        return this.#instance;
    }

    private constructor() {
        super();
    }

    public async add(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async update(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async delete(): Promise<void> {
        throw new UnavailableMethodException();
    }

    protected endpoint(params: TranslatableCollectionData): string {
        return `/api/houses/${params.id}/descriptions/general/${params.language}`;
    }

    protected onError(error: HttpError<AjaxResponseError>): void {
        throw new Error(error.body?.message);
    }
}
