import { Observer, Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import DescriptionCollectionStorage from './DescriptionCollectionStorage';
import NatureDescriptionCollectionStorage from './NatureDescriptionCollectionStorage';
import ReviewCollectionStorage from './ReviewCollectionStorage';
import TranslatableContent, {
    TranslatableContentResponse,
    TranslationStorageType
} from './TranslatableContent';
import { iTranslationStorage } from './iTranslationStorage';

export default class TranslatableContentManager implements Observer {
    static #instance: TranslatableContentManager | null = null;

    public static getInstance(): TranslatableContentManager {
        if (this.#instance === null) {
            this.#instance = new TranslatableContentManager();
        }

        return this.#instance;
    }

    private constructor() {
        ReviewCollectionStorage.getInstance().attach(this);
        DescriptionCollectionStorage.getInstance().attach(this);
        NatureDescriptionCollectionStorage.getInstance().attach(this);
    }

    public update(subject: Subject): void {
        const storage = subject as unknown as iTranslationStorage;
        if (!storage || !storage.translationData) {
            return;
        }

        if (Array.isArray(storage.translationData)) {
            for (const data of storage.translationData) {
                this.#sendUpdate(data);
            }
            return;
        }

        this.#sendUpdate(storage.translationData);
    }

    #sendUpdate(data: TranslatableContentResponse): void {
        const id = data.id;
        const type = data.type || null;
        const elements = this.#getElements(id, type);

        for (const element of elements) {
            element.update(data);
        }
    }

    #getElements(id: number, type: TranslationStorageType | null = null): TranslatableContent[] {
        let selectorString = `nh-translatable-content[translation-id="${id.toString()}"]`;
        if (type) {
            selectorString += `[type="${type}"]`;
        }

        return Array.from(document.querySelectorAll(selectorString)) as TranslatableContent[];
    }
}
