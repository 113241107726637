import UnavailableMethodException from '@naturehouse/nh-essentials/lib/exceptions/UnavailableMethodException';
import HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';
import CollectionBase from '../../../../common/CollectionBase';
import { TranslatableCollectionData } from '../Translations/TranslatableContent';

export type ReviewCollectionParamsType = {
    houseId: string;
    skip?: number;
};

export type ReviewItem = {
    id: number;
    initials: string;
    arrivalDate: string;
    departureDate: string;
    natureRating: number;
    natureComment: string;
    natureCommentTranslated: string | null;
    houseRating: number;
    houseComment: string;
    houseCommentTranslated: string | null;
    language: string;
    originalLanguage: string;
};

export type ReviewCollectionResponse = {
    limit: number;
    total: number;
    skip: number;
    reviews: ReviewItem[];
};

export default class ReviewCollection extends CollectionBase<
    TranslatableCollectionData,
    ReviewCollectionParamsType
> {
    static #instance: ReviewCollection | null = null;

    public static getInstance(): ReviewCollection {
        if (this.#instance === null) {
            this.#instance = new ReviewCollection();
        }

        return this.#instance;
    }

    private constructor() {
        super();
    }

    public async retrieve<RT = AjaxResponse<ReviewCollectionResponse>>(
        params?: ReviewCollectionParamsType
    ): Promise<RT> {
        return super.retrieve<AjaxResponse>(params) as Promise<RT>;
    }

    public add<RT = AjaxResponse>(): Promise<RT | void> {
        throw new UnavailableMethodException();
    }

    public async delete(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async update(): Promise<void> {
        throw new UnavailableMethodException();
    }

    protected endpoint(params: ReviewCollectionParamsType): string {
        if (!params || !params.houseId) {
            throw new Error('houseId is required');
        }

        const endpoint = `/api/houses/${params.houseId}/reviews`;

        if (params.skip === undefined) {
            return endpoint;
        }

        return `${endpoint}?skip=${params.skip}`;
    }

    protected onError(error: HttpError<AjaxResponseError>): void {
        throw new Error(error.body?.message);
    }
}
