import InitializationError from '@naturehouse/nh-essentials/lib/exceptions/InitializationError';
import TranslationManager from '../../../../common/TranslationManager';

export enum TranslatableContentSwitchEvent {
    CURRENT = 'translatable_content_switch_current',
    SOURCE = 'translatable_content_switch_source'
}

export type TranslatableContentSwitchTranslations = {
    currentLabel: string;
    currentButtonLabel: string;
    sourceLabel: string;
    sourceButtonLabel: string;
};

export enum TranslatableContentSwitchVersion {
    CURRENT = 'current',
    SOURCE = 'source'
}

export default class TranslatableContentSwitch extends HTMLElement {
    static get observedAttributes(): string[] {
        return ['version'];
    }

    #translations: TranslatableContentSwitchTranslations | null = null;

    #label = this.querySelector('[data-role="label"]') as HTMLElement;

    #toggle = this.querySelector('[data-role="toggle"]') as HTMLElement;

    get version(): TranslatableContentSwitchVersion | null {
        return this.getAttribute('version') as TranslatableContentSwitchVersion;
    }

    set version(value: TranslatableContentSwitchVersion) {
        this.setAttribute('version', value.toString());
    }

    public async connectedCallback(): Promise<void> {
        if (this.#translations === null) {
            this.#translations = await this.#getTranslations();
        }
        this.#reinitializeComponent();

        this.#toggle.addEventListener('click', this.#handleToggle);
    }

    public disconnectedCallback(): void {
        this.#toggle.removeEventListener('click', this.#handleToggle);
    }

    public async attributeChangedCallback(
        name: string,
        oldValue: string,
        newValue: string
    ): Promise<void> {
        if (name !== 'version' || oldValue === null || oldValue === newValue) {
            return;
        }

        this.#reinitializeComponent();

        if (newValue === TranslatableContentSwitchVersion.CURRENT) {
            this.#setCurrent();
            return;
        }

        if (newValue === TranslatableContentSwitchVersion.SOURCE) {
            this.#setSource();
        }
    }

    readonly #handleToggle = (): void => {
        if (this.version === TranslatableContentSwitchVersion.SOURCE) {
            this.version = TranslatableContentSwitchVersion.CURRENT;
            return;
        }

        this.version = TranslatableContentSwitchVersion.SOURCE;
    };

    readonly #setCurrent = (): void => {
        this.dispatchEvent(new Event(TranslatableContentSwitchEvent.CURRENT));

        this.#label.innerHTML = this.#translations?.currentLabel || '';
        this.#toggle.innerHTML = this.#translations?.currentButtonLabel || '';
    };

    readonly #setSource = (): void => {
        this.dispatchEvent(new Event(TranslatableContentSwitchEvent.SOURCE));

        this.#label.innerHTML = this.#translations?.sourceLabel ?? '';
        this.#toggle.innerHTML = this.#translations?.sourceButtonLabel ?? '';
    };

    #reinitializeComponent(): void {
        this.#label = this.querySelector('[data-role="label"]') as HTMLElement;
        this.#toggle = this.querySelector('[data-role="toggle"]') as HTMLElement;

        if (this.#label && this.#toggle) {
            return;
        }

        throw new InitializationError({
            label: this.#label?.outerHTML,
            toggle: this.#toggle?.outerHTML,
            component: this.outerHTML
        });
    }

    async #getTranslations(): Promise<TranslatableContentSwitchTranslations> {
        const translationManager = await TranslationManager.getInstance();
        const translations: TranslatableContentSwitchTranslations = {
            currentLabel: translationManager.translate('automatically_translated.description'),
            currentButtonLabel: translationManager.translate('show_original.label'),
            sourceLabel: translationManager.translate('translate_to_original.description'),
            sourceButtonLabel: translationManager.translate('translate_to.label')
        };

        return translations;
    }
}

if (!customElements.get('nh-translatable-content-switch')) {
    customElements.define('nh-translatable-content-switch', TranslatableContentSwitch);
}
