import { convertDateStringToDate } from '@naturehouse/nh-essentials/lib/dates/date';
import { formatDateForUrl } from '../../../util/dateHelper';

export default class OpenNewChat {
    private readonly context: ContextInterface;

    private form: HTMLFormElement | null = null;

    constructor(context: ContextInterface) {
        this.context = context;
    }

    public onInit(): void {
        this.form = document.querySelector('form[name="price_calculation"]');
        this.context.element.addEventListener('click', (e) => this.openChat(e));
    }

    private openChat(event: Event): void {
        event.preventDefault();
        if (!this.form || !(this.context.element instanceof HTMLAnchorElement)) {
            return;
        }

        const formData = new FormData(this.form);
        let url = new URL(this.context.element.href);
        url = this.appendDatesToUrl(url, formData);
        url = this.appendGuestsToUrl(url, formData);

        window.location.href = url.href;
    }

    private appendDatesToUrl(url: URL, formData: FormData): URL {
        const dates: StandardObjectInterface = {
            arrivalDate: convertDateStringToDate(
                formData.get('price_calculation[dateRange][arrivalDate]')?.toString() || ''
            ),
            departureDate: convertDateStringToDate(
                formData.get('price_calculation[dateRange][departureDate]')?.toString() || ''
            )
        };

        Object.keys(dates).forEach((key) => {
            const value = dates[key];
            if (value) {
                url.searchParams.append(key, formatDateForUrl(value));
            }
        });

        return url;
    }

    private appendGuestsToUrl(url: URL, formData: FormData): URL {
        const guests: StandardObjectInterface = {
            adultAmount: formData.get('price_calculation[adultAmount]'),
            childrenAmount: formData.get('price_calculation[childrenAmount]'),
            babyAmount: formData.get('price_calculation[babyAmount]'),
            petAmount: formData.get('price_calculation[petAmount]')
        };

        Object.keys(guests).forEach((key) => {
            const value = guests[key];
            if (value) {
                url.searchParams.append(key, value);
            }
        });

        return url;
    }

    static getClassName(): string {
        return 'OpenNewChat';
    }
}
