import UnavailableMethodException from '@naturehouse/nh-essentials/lib/exceptions/UnavailableMethodException';
import HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';
import CollectionBase from '../../../common/CollectionBase';

export type SustainabilityApiData = string[];

export type SustainabilityCollectionParamsType = {
    houseId: string;
};

export default class SustainabilityCollection extends CollectionBase<SustainabilityApiData> {
    static #instance: SustainabilityCollection | null = null;

    public static getInstance(): SustainabilityCollection {
        if (this.#instance === null) {
            this.#instance = new SustainabilityCollection();
        }

        return this.#instance;
    }

    public async retrieve<RT = SustainabilityApiData>(
        params: SustainabilityCollectionParamsType
    ): Promise<RT | void> {
        return super.retrieve<RT>(params);
    }

    public async add(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async delete(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async update(): Promise<void> {
        throw new UnavailableMethodException();
    }

    protected endpoint(params: SustainabilityCollectionParamsType): string {
        return `/api/houses/${params.houseId}/sustainability`;
    }

    protected onError(error: HttpError<AjaxResponseError>): void {
        throw new Error(error.body?.message);
    }
}
