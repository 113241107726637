import CollectionBase from '../../../../common/CollectionBase';
import DescriptionCollection from './DescriptionCollection';
import DescriptionCollectionStorage from './DescriptionCollectionStorage';
import NatureDescriptionCollection from './NatureDescriptionCollection';
import NatureDescriptionCollectionStorage from './NatureDescriptionCollectionStorage';
import ReviewCollectionStorage from './ReviewCollectionStorage';
import ReviewTranslationCollection from './ReviewTranslationCollection';
import TranslationFetcherStorage, {
    TranslationFetcherStorageType
} from './TranslationFetcherStorage';
import { iTranslationStorage } from './iTranslationStorage';

export enum TranslatableContentCollection {
    DESCRIPTION = 'description',
    NATURE_DESCRIPTION = 'nature-description',
    REVIEW = 'review'
}

export enum TranslatableContentProperty {
    SOURCE = 'sourceContent',
    CURRENT = 'currentContent'
}

export type TranslatableCollectionData = {
    id: string;
    houseId?: string;
    language: string;
};

export enum TranslationStorageType {
    REVIEW_HOUSE_COMMENT = 'reviewHouseComment',
    REVIEW_NATURE_COMMENT = 'reviewNatureComment',
    HOUSE_DESCRIPTION = 'houseDescription',
    NATURE_DESCRIPTION = 'natureDescription'
}

export type TranslatableContentResponse = {
    id: number;
    language: string;
    text: string;
    type?: TranslationStorageType;
};

type ShowContentWrapperParams = {
    contentLanguage: string;
    storeLanguage: string;
    contentProperty: TranslatableContentProperty;
    storeProperty: TranslatableContentProperty;
    showContentWrapperCallback: () => void;
};

export default class TranslatableContent extends HTMLElement {
    readonly #storage = TranslationFetcherStorage.getInstance();

    #currentContentWrapper = this.querySelector('[data-role="current-content"]') as HTMLElement;

    #sourceContentWrapper = this.querySelector('[data-role="source-content"]') as HTMLElement;

    readonly #collection: string | null = this.getAttribute('collection');

    get collection(): CollectionBase<TranslatableCollectionData> {
        if (this.#collection === TranslatableContentCollection.DESCRIPTION) {
            return DescriptionCollection.getInstance();
        }

        if (this.#collection === TranslatableContentCollection.NATURE_DESCRIPTION) {
            return NatureDescriptionCollection.getInstance();
        }

        if (this.#collection === TranslatableContentCollection.REVIEW) {
            return ReviewTranslationCollection.getInstance();
        }

        throw new Error('Attribute collection does not contain a valid value!');
    }

    get collectionStorage(): iTranslationStorage {
        if (this.#collection === TranslatableContentCollection.DESCRIPTION) {
            return DescriptionCollectionStorage.getInstance();
        }

        if (this.#collection === TranslatableContentCollection.NATURE_DESCRIPTION) {
            return NatureDescriptionCollectionStorage.getInstance();
        }

        if (this.#collection === TranslatableContentCollection.REVIEW) {
            return ReviewCollectionStorage.getInstance();
        }

        throw new Error('Attribute collection does not contain a valid value!');
    }

    readonly #sourceLanguage: string = this.getAttribute('source-language') ?? '';

    get sourceLanguage(): string {
        return this.#sourceLanguage;
    }

    readonly #currentLanguage: string = this.getAttribute('current-language') ?? '';

    get currentLanguage(): string {
        return this.#currentLanguage;
    }

    readonly #translationId: string = this.getAttribute('translation-id') ?? '';

    get translationId(): string {
        return this.#translationId;
    }

    get currentContent(): string {
        if (!this.#currentContentWrapper) {
            return '';
        }

        return this.#currentContentWrapper.innerHTML.trim();
    }

    set currentContent(value: string) {
        if (!this.#currentContentWrapper) {
            return;
        }

        this.#currentContentWrapper.innerHTML = value;
    }

    get sourceContent(): string {
        if (!this.#sourceContentWrapper) {
            return '';
        }

        return this.#sourceContentWrapper.innerHTML.trim();
    }

    set sourceContent(value: string) {
        if (!this.#sourceContentWrapper) {
            return;
        }

        this.#sourceContentWrapper.innerHTML = value;
    }

    public connectedCallback(): void {
        this.#currentContentWrapper = this.querySelector(
            '[data-role="current-content"]'
        ) as HTMLElement;

        this.#sourceContentWrapper = this.querySelector(
            '[data-role="source-content"]'
        ) as HTMLElement;

        if (!this.hasAttribute('translation-id')) {
            throw new Error('Missing required attribute translation-id!');
        }

        if (!this.hasAttribute('collection')) {
            throw new Error('Missing required attribute collection!');
        }

        if (!this.hasAttribute('source-language')) {
            throw new Error('Missing required attribute source-language!');
        }

        if (!this.hasAttribute('current-language')) {
            throw new Error('Missing required attribute current-language!');
        }

        if (!this.#currentContentWrapper) {
            throw new Error('Missing required current-content wrapper!');
        }

        if (!this.#sourceContentWrapper) {
            throw new Error('Missing required source-content wrapper!');
        }
    }

    public update(data: TranslatableContentResponse): void {
        const contentType =
            this.getAttribute('current-language') === data.language
                ? 'current-content'
                : 'source-content';

        const contentProperty = this.querySelector(`[data-role="${contentType}"]`);
        if (!contentProperty) {
            throw new Error('Missing required content property!');
        }

        contentProperty.innerHTML = data.text;

        if (contentType === 'current-content') {
            this.#showCurrentContentWrapper();
            return;
        }

        this.#showSourceContentWrapper();
    }

    public async showSource(): Promise<void> {
        await this.#showContentWrapper({
            contentLanguage: this.sourceLanguage,
            storeLanguage: this.currentLanguage,
            contentProperty: TranslatableContentProperty.SOURCE,
            storeProperty: TranslatableContentProperty.CURRENT,
            showContentWrapperCallback: this.#showSourceContentWrapper
        });
    }

    public async showCurrent(): Promise<void> {
        await this.#showContentWrapper({
            contentLanguage: this.currentLanguage,
            storeLanguage: this.sourceLanguage,
            contentProperty: TranslatableContentProperty.CURRENT,
            storeProperty: TranslatableContentProperty.SOURCE,
            showContentWrapperCallback: this.#showCurrentContentWrapper
        });
    }

    readonly #showContentWrapper = async (params: ShowContentWrapperParams): Promise<void> => {
        const {
            contentLanguage,
            storeLanguage,
            contentProperty,
            storeProperty,
            showContentWrapperCallback
        } = params;

        const content = this.#getStoredContent();

        if (this[contentProperty]) {
            showContentWrapperCallback();
            return;
        }

        if (content && content[contentProperty]) {
            showContentWrapperCallback();
            this[contentProperty] = content[contentProperty] ?? '';
            return;
        }

        this.#storeContent(storeLanguage, storeProperty);

        const element = document.getElementById('house-id') as HTMLMetaElement | null;
        const houseId = element?.content ?? '';

        await this.#fetchData(contentLanguage, houseId);
    };

    async #fetchData(contentLanguage: string, houseId: string): Promise<void> {
        await this.collection.retrieve<TranslatableContentResponse>({
            id: this.translationId,
            language: contentLanguage,
            houseId: houseId
        });
    }

    readonly #storeContent = (
        language: string,
        contentProperty: TranslatableContentProperty
    ): void => {
        const storeObject: Partial<TranslationFetcherStorageType> = {
            type: this.#getType(),
            id: this.translationId
        };

        storeObject[contentProperty] = this[contentProperty] ?? '';
        storeObject[language === this.currentLanguage ? 'currentLanguage' : 'sourceLanguage'] =
            language;

        this.#storage.add(storeObject as TranslationFetcherStorageType);
    };

    readonly #getStoredContent = (): TranslationFetcherStorageType | undefined =>
        this.#storage.get(this.#getType(), this.translationId);

    readonly #getType = (): TranslatableContentCollection =>
        this.collectionStorage?.constructor.name as TranslatableContentCollection;

    readonly #showCurrentContentWrapper = (): void => {
        this.#currentContentWrapper.hidden = false;
        this.#sourceContentWrapper.hidden = true;
    };

    readonly #showSourceContentWrapper = (): void => {
        this.#sourceContentWrapper.hidden = false;
        this.#currentContentWrapper.hidden = true;
    };
}

if (!customElements.get('nh-translatable-content')) {
    customElements.define('nh-translatable-content', TranslatableContent);
}
