import type { DefaultComponentType } from '../../../../src/utils/types';
import type { IconProps } from '../../../../src/components/protons/icon/Icon';
import template from './List.html.njk';

export enum Orientation {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal'
}

export type ListItem = DefaultComponentType & {
    label?: string;
    icon?: IconProps;
};

export type ListProps = DefaultComponentType & {
    orientation?: Orientation;
    items: ListItem[];
};

const ListComponent = (props: ListProps) => template.render(props);

export default ListComponent;
