import type { DefaultComponentType } from '../../../../src/utils/types';
import './Spinner.pcss';

export enum SpinnerVariants {
    LIGHT = 'light',
    DARK = 'dark'
}

export enum SpinnerSizes {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

export type SpinnerProps = DefaultComponentType & {
    size?: SpinnerSizes;
    variant?: SpinnerVariants;
};

export default class Spinner extends HTMLElement {
    get size(): string | undefined {
        const size: string | null = this.getAttribute('size');
        if (size === null) {
            return undefined;
        }

        const sizes: string[] = Object.values<string>(SpinnerSizes);
        return sizes.includes(size) ? size : undefined;
    }

    set size(value: string | undefined) {
        if (!value) {
            this.setAttribute('size', SpinnerSizes.SMALL);
            return;
        }

        const sizes: string[] = Object.values<string>(SpinnerSizes);
        if (!sizes.includes(value)) {
            this.setAttribute('size', SpinnerSizes.SMALL);
            return;
        }

        this.setAttribute('size', value);
    }

    get variant(): string | undefined {
        const variant: string | null = this.getAttribute('variant');
        if (variant === null) {
            return undefined;
        }

        const variants: string[] = Object.values<string>(SpinnerVariants);
        return variants.includes(variant) ? variant : undefined;
    }

    set variant(value: string | undefined) {
        if (!value) {
            this.setAttribute('variant', SpinnerVariants.DARK);
            return;
        }

        const variant: string[] = Object.values<string>(SpinnerVariants);
        if (!variant.includes(value)) {
            this.setAttribute('variant', SpinnerVariants.DARK);
            return;
        }

        this.setAttribute('variant', value);
    }

    protected connectedCallback(): void {
        this.variant = this.getAttribute('variant') || SpinnerVariants.DARK;
        this.size = this.getAttribute('size') || SpinnerSizes.SMALL;
    }
}

if (!window.customElements.get('nh-spinner')) {
    window.customElements.define('nh-spinner', Spinner);
}
