import UnavailableMethodException from '@naturehouse/nh-essentials/lib/exceptions/UnavailableMethodException';
import HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';
import CollectionBase from '../../../../common/CollectionBase';
import { TranslatableCollectionData, TranslatableContentResponse } from './TranslatableContent';

export type ReviewTranslationCollectionResponse = {
    house: TranslatableContentResponse;
    nature: TranslatableContentResponse;
};

export default class ReviewTranslationCollection extends CollectionBase<TranslatableCollectionData> {
    static #instance: ReviewTranslationCollection | null = null;

    public static getInstance(): ReviewTranslationCollection {
        if (this.#instance === null) {
            this.#instance = new ReviewTranslationCollection();
        }

        return this.#instance;
    }

    private constructor() {
        super();
    }

    public async add(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async update(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async delete(): Promise<void> {
        throw new UnavailableMethodException();
    }

    protected endpoint(params: TranslatableCollectionData): string {
        if (!params.houseId) {
            throw new Error('House ID is not defined!');
        }

        return `/api/houses/${params.houseId}/reviews/${params.id}/${params.language}`;
    }

    protected onError(error: HttpError<AjaxResponseError>): void {
        throw new Error(error.body?.message);
    }
}
