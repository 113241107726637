import AmountSelectorComponent from '../../../modules/shared/components/AmountSelectorComponent';
import Modal from '../modal/Modal';

type ModalProps = {
    title: string;
    maxPeople: string;
    contentTemplate: HTMLTemplateElement;
    footerTemplate: HTMLTemplateElement;
};

export default class TravelPartyModalFactory {
    static createModal(props: ModalProps): Modal {
        const modal = new Modal({
            title: props.title,
            classList: ['search-form-modal', 'nh-modal--minimal'],
            hidden: true
        });
        modal.dataset.for = 'travel-party';
        modal.dataset.pw = 'travel-party-dialog';

        const content = document.importNode(props.contentTemplate.content, true);
        const footer = document.importNode(props.footerTemplate.content, true);

        modal.content = content;
        modal.footer = footer;

        const amountSelector = new AmountSelectorComponent(modal, Number(props.maxPeople));
        amountSelector.initialize();

        return modal;
    }
}
