import reviewItemTemplate from '@templates/website/detail/partials/_review-item.html.njk';
import AbstractSubject, {
    Subject
} from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import { getLocale } from '@naturehouse/nh-essentials/lib/dates/date';
import { onIntersectionObserver } from '@naturehouse/nh-essentials/lib/events/eventHandling';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import TranslationManager, { TranslationType } from '../../../../common/TranslationManager';
import { ReviewItem } from './ReviewCollection';
import ReviewListItems from './ReviewListItems';

export default class ReviewListManager extends AbstractSubject {
    static #instance: ReviewListManager | null = null;

    public static getInstance(): ReviewListManager {
        if (ReviewListManager.#instance === null) {
            ReviewListManager.#instance = new ReviewListManager();
        }

        return ReviewListManager.#instance;
    }

    public static destroyInstance(): void {
        this.#instance = null;
    }

    readonly #element: HTMLOListElement;

    readonly #houseId: string | null = null;

    readonly #reviewListItems = ReviewListItems.getInstance();

    public constructor() {
        super();

        this.#element = document.getElementById('nh-review-list') as HTMLOListElement;
        this.#houseId = this.#element?.dataset.houseId ?? null;

        if (this.#element === null || this.#houseId === null) {
            return;
        }

        onIntersectionObserver(this.#element, this.#renderComponent);

        this.#reviewListItems.attach(this);
    }

    public async update(subject: Subject): Promise<void> {
        if (!(subject instanceof ReviewListItems)) {
            return;
        }

        const translations = await this.#getTranslations();
        this.#renderItems(this.#reviewListItems.reviews, translations);
    }

    readonly #renderComponent = async (): Promise<void> => {
        this.#reviewListItems.houseId = this.#houseId;
        await this.#reviewListItems.fetchReviews();

        const translations = await this.#getTranslations();

        this.#renderItems(this.#reviewListItems.reviews, translations);
    };

    async #getTranslations(): Promise<TranslationType> {
        const translationManager = await TranslationManager.getInstance();
        return {
            read_more: translationManager.translate('Lees verder'),
            read_less: translationManager.translate('Verberg tekst'),
            nature_review_score: translationManager.translate('nature_review_score.label'),
            overall_rating: translationManager.translate('overall_rating.label'),
            currentLabel: translationManager.translate('automatically_translated.description'),
            currentButtonLabel: translationManager.translate('show_original.label'),
            translateButtonLabel: translationManager.translate('translate_to.label')
        };
    }

    readonly #renderItems = (items: ReviewItem[], translations: Record<string, string>): void => {
        this.#element.innerHTML = '';

        for (const item of items) {
            const reviewElement = this.#renderItem(item, translations);
            this.#element.appendChild(reviewElement);
        }

        this.#element.classList.remove('loading-spinner');
    };

    readonly #renderItem = (
        item: ReviewItem,
        translations: Record<string, string>
    ): HTMLElement => {
        const isTranslated = this.#getIsTranslated(item);
        const hasLanguageSwitcher = getLocale() !== item.originalLanguage;

        const data = {
            ...item,
            houseComment: item.houseComment,
            houseCommentTranslated: item.houseCommentTranslated,
            natureComment: item.natureComment,
            natureCommentTranslated: item.natureCommentTranslated,
            translations,
            isTranslated,
            hasLanguageSwitcher
        };

        const html: HTMLElement = parseStringAsHtml(reviewItemTemplate.render(data), 'li');

        return html;
    };

    #getIsTranslated(item: ReviewItem): boolean {
        return item.houseCommentTranslated !== null;
    }
}
