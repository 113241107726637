import AbstractSubject, {
    Observer
} from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import { CollectionEvents } from '../../../../common/CollectionBase';
import NatureDescriptionCollection from './NatureDescriptionCollection';
import { TranslatableContentResponse, TranslationStorageType } from './TranslatableContent';
import { iTranslationStorage } from './iTranslationStorage';

export default class NatureDescriptionCollectionStorage
    extends AbstractSubject
    implements Observer, iTranslationStorage
{
    static #instance: NatureDescriptionCollectionStorage | null = null;

    public static getInstance(): NatureDescriptionCollectionStorage {
        if (this.#instance === null) {
            this.#instance = new NatureDescriptionCollectionStorage();
        }

        return this.#instance;
    }

    public static destroyInstance(): void {
        this.#instance = null;
    }

    readonly #collection: NatureDescriptionCollection = NatureDescriptionCollection.getInstance();

    #translationData: TranslatableContentResponse | null = null;

    get translationData(): TranslatableContentResponse | null {
        return this.#translationData;
    }

    private constructor() {
        super();
        this.#collection.attach(this);
    }

    public update(subject: CustomEvent): void {
        if (subject.type !== CollectionEvents.ITEM_RETRIEVED) {
            return;
        }

        this.#translationData = subject.detail.data as TranslatableContentResponse;
        this.#translationData.type = TranslationStorageType.NATURE_DESCRIPTION;
        this.notify();
    }
}
