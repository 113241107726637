/**
 * @deprecated
 */
export default class AmountSelectorComponent {
    readonly #container: HTMLElement;

    readonly #maxPeople: number;

    #totalAmount = 0;

    #labelElement: HTMLElement | null = null;

    #fields: HTMLInputElement[] = [];

    constructor(container: HTMLElement, maxPeople: number) {
        this.#container = container;
        this.#maxPeople = maxPeople;
    }

    public initialize(): void {
        this.#labelElement = this.#container.querySelector('[data-default-label]');
        this.#fields = Array.from(this.#container.querySelectorAll('[type="number"]'));
        this.#fields.forEach((field: HTMLInputElement) => {
            field.addEventListener('change', (e: Event) => this.#handleChange(e as CustomEvent));
            this.#totalAmount += +field.value;
        });

        this.#fields.forEach((field: HTMLInputElement) => {
            this.#handleMaxValue(field);
        });

        this.#updateCaption();
        this.#handleChange();
    }

    #handleMaxValue(field: HTMLInputElement): void {
        const inputValue = Number(field.value);
        const inputMax = Number(field.max);

        if (inputValue >= inputMax) {
            field.dispatchEvent(new CustomEvent('disable-add-button'));
            return;
        }

        field.dispatchEvent(new CustomEvent('enable-add-button'));
    }

    #handleChange(e?: Event): void {
        const event = e as CustomEvent;
        this.#updateCaption();

        if (!this.#maxPeople) {
            return;
        }
        const changedInput: HTMLInputElement | null =
            event && event.detail && event.detail.input ? event.detail.input : null;

        this.#totalAmount = 0;

        this.#fields.forEach((field: HTMLInputElement) => {
            if (field.dataset.countAsTotal === 'true') {
                this.#totalAmount += Number(field.value);
            }
        });

        const countAsTotalFields: HTMLInputElement[] = Array.from(
            this.#container.querySelectorAll('[data-count-as-total="true"]')
        );

        countAsTotalFields.forEach((f: HTMLInputElement) => {
            const field = f;
            const max = this.#getMaxValue(field, changedInput);
            field.max = max.toString();
        });

        if (this.#totalAmount >= this.#maxPeople) {
            let amountLeft = this.#maxPeople;
            this.#fields.forEach((field: HTMLInputElement) => {
                if (!field.dataset.countAsTotal || field.dataset.countAsTotal === 'false') {
                    this.#handleMaxValue(field);
                    return;
                }

                const inputMax = Math.min(Number(field.max), amountLeft);
                if (!changedInput && Number(field.value) >= inputMax) {
                    field.value = inputMax < 0 ? '0' : inputMax.toString();
                }

                if (!changedInput && this.#totalAmount >= inputMax) {
                    setTimeout(() => {
                        field.dispatchEvent(new CustomEvent('disable-add-button'));
                    }, 1);
                }

                amountLeft -= Number(field.value);
            });
        } else {
            this.#fields.forEach((field: HTMLInputElement) => {
                if (!field.dataset.countAsTotal || field.dataset.countAsTotal === 'false') {
                    this.#handleMaxValue(field);
                    return;
                }

                field.dispatchEvent(new CustomEvent('enable-add-button'));
            });
        }

        if (changedInput && this.#totalAmount > this.#maxPeople) {
            const reduceValue = this.#totalAmount - this.#maxPeople;
            const newValue = Number(changedInput.value) - reduceValue;
            changedInput.value = newValue < 0 ? '0' : newValue.toString();
        }

        this.#updateCaption();
    }

    #getMaxValue(input: HTMLInputElement, changedInput: HTMLInputElement | null): number {
        if (!changedInput) {
            return this.#maxPeople;
        }

        let max = this.#maxPeople;

        const countAsTotalFields: HTMLInputElement[] = Array.from(
            this.#container.querySelectorAll('[data-count-as-total="true"]')
        );

        countAsTotalFields.forEach((f) => {
            const field = f;
            if (field === input) {
                return;
            }

            max -= Number(field.value);
        });

        return max;
    }

    #updateCaption(): void {
        if (!this.#labelElement) {
            return;
        }

        if (this.#totalAmount === 0) {
            this.#labelElement.textContent = this.#labelElement.dataset.defaultLabel || '';
            return;
        }

        this.#labelElement.textContent = this.#resolveAmountText();
    }

    #resolveAmountText(): string {
        let labelText = '';

        this.#fields.forEach((field, index) => {
            if (index !== 0) {
                labelText += ', ';
            }

            labelText += `${Number(field.value)} ${this.#getLabelText(field)}`;
        });

        return labelText;
    }

    #getLabelText(field: HTMLInputElement): string {
        if (Number(field.value) === 1) {
            return field.dataset.labelSingle || '';
        }

        return field.dataset.labelPlural || '';
    }
}
