import type Dialog from '@naturehouse/design-system/components/molecules/dialog/Dialog';
import dialogTemplate from '@naturehouse/design-system/components/molecules/dialog/Dialog.html.njk';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import TranslationManager from '../../../common/TranslationManager';

export class SustainabilityDialogRenderer {
    public async render(): Promise<Dialog> {
        const tm = await TranslationManager.getInstance();
        return parseStringAsHtml(
            dialogTemplate.render({
                id: `detail-sustainability-dialog`,
                title: `${tm.translate('sustainability.title')}`,
                closeButton: true,
                content: '',
                hideFooter: true
            }),
            'nh-dialog'
        ) as Dialog;
    }
}

export default new SustainabilityDialogRenderer();
