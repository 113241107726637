var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/button/Button.macro.njk"] = require( "@naturehouse/design-system/components/atoms/button/Button.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/organisms/image-viewer/partials/_controls.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/atoms/button/Button.macro.njk", false, "design-system/v3/components/organisms/image-viewer/partials/_controls.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("buttonTemplate", t_1);
output += "\n\n";
var t_4;
t_4 = {"name": "arrow-left","size": 1.5};
frame.set("leftArrow", t_4, true);
if(frame.topLevel) {
context.setVariable("leftArrow", t_4);
}
if(frame.topLevel) {
context.addExport("leftArrow", t_4);
}
output += "\n";
var t_5;
t_5 = {"name": "arrow-right","size": 1.5};
frame.set("rightArrow", t_5, true);
if(frame.topLevel) {
context.setVariable("rightArrow", t_5);
}
if(frame.topLevel) {
context.addExport("rightArrow", t_5);
}
output += "\n\n";
output += runtime.suppressValue((lineno = 11, colno = 24, runtime.callWrap(runtime.memberLookup((t_1),"render"), "buttonTemplate[\"render\"]", context, [{"variant": "floating","classes": ["nh-image-viewer__close"],"label": "","data": {"data-role": "image-viewer-close"},"icon": {"name": "close"}}])), env.opts.autoescape);
output += "\n\n<div class=\"nh-image-viewer__controls\">\n    ";
output += runtime.suppressValue((lineno = 24, colno = 28, runtime.callWrap(runtime.memberLookup((t_1),"render"), "buttonTemplate[\"render\"]", context, [{"variant": "dark","icon": runtime.contextOrFrameLookup(context, frame, "leftArrow"),"data": {"data-role": "prev"}}])), env.opts.autoescape);
output += "\n    ";
output += runtime.suppressValue((lineno = 31, colno = 28, runtime.callWrap(runtime.memberLookup((t_1),"render"), "buttonTemplate[\"render\"]", context, [{"variant": "dark","icon": runtime.contextOrFrameLookup(context, frame, "rightArrow"),"data": {"data-role": "next"}}])), env.opts.autoescape);
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/organisms/image-viewer/partials/_controls.html.njk"] , dependencies)