import '../../atoms/loader/Spinner';
import '../../atoms/button/Button';
import '../../atoms/image/Image';
import '../image-carousel/ImageCarousel';
/* eslint-disable import/no-duplicates */
import '../../molecules/dialog/Dialog';
import Dialog, { DialogEvents } from '../../molecules/dialog/Dialog';
import '../../atoms/carousel-indicators/CarouselIndicators';
import CarouselIndicators, {
    CarouselIndicatorsEvents
} from '../../atoms/carousel-indicators/CarouselIndicators';
/* eslint-enable import/no-duplicates */
import SlidesCarousel, {
    SlidesCarouselEvent
} from '../../molecules/slides-carousel/SlidesCarousel';
import type { ImageProps } from '../../atoms/image/Image';
import controlsTemplate from './partials/_controls.html.njk';
import indicatorsTemplate from './partials/_indicators.html.njk';
import sliderTemplate from './partials/_slider.html.njk';
import titleTemplate from './partials/_title.html.njk';
import './ImageViewer.pcss';

export type ImageViewerProps = {
    slides: ImageProps[];
    activeIndex?: number;
    loadNextImage?: boolean;
};

export enum ImageViewerEvents {
    SELECTED_INDICATOR = 'image-viewer-active-index',
    NEXT_BUTTON_CLICK = 'image-viewer-next-button-click',
    PREV_BUTTON_CLICK = 'image-viewer-prev-button-click',
    NEXT_SWIPE_ACTION = 'image-viewer-next-swipe-action',
    PREV_SWIPE_ACTION = 'image-viewer-prev-swipe-action',
    SLIDE_CHANGED = 'image-viewer-slide-changed'
}

export default class ImageViewer extends Dialog {
    static get observedAttributes(): string[] {
        return ['active-index'];
    }

    #animate = true;

    #touchStartX = 0;

    #slides: ImageProps[] = JSON.parse(this.getAttribute('slides') || '[]').filter(
        (slide: ImageProps) => slide.src !== undefined && slide.alt !== undefined
    );

    #slidesCarousel: SlidesCarousel | null = null;

    get slidesCarousel(): SlidesCarousel | null {
        return this.#slidesCarousel;
    }

    #prevButton: HTMLElement | null = null;

    #closeButtons: HTMLButtonElement[] = [];

    get prevButton(): HTMLElement | null {
        return this.#prevButton;
    }

    #nextButton: HTMLElement | null = null;

    get nextButton(): HTMLElement | null {
        return this.#nextButton;
    }

    #indicators: CarouselIndicators | null = null;

    get activeIndex(): number {
        return Number(this.getAttribute('active-index'));
    }

    set activeIndex(value: number) {
        const index: number = this.#determineIndex(value);

        this.setAttribute('active-index', index.toString());
    }

    protected connectedCallback(): void {
        super.connectedCallback();

        this.addEventListener(DialogEvents.OPENED, this.#dialogOpenedCallback);
        this.addEventListener(
            CarouselIndicatorsEvents.SELECTED_INDICATOR,
            this.#handleSelectedIndicator
        );
        this.addEventListener('keydown', this.#handleKeyDown);
    }

    protected disconnectedCallback(): void {
        super.disconnectedCallback();

        this.removeEventListener(DialogEvents.OPENED, this.#dialogOpenedCallback);
        this.removeEventListener(
            CarouselIndicatorsEvents.SELECTED_INDICATOR,
            this.#handleSelectedIndicator
        );
        this.removeEventListener('keydown', this.#handleKeyDown);

        this.#disconnectElements();
    }

    protected attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === 'active-index' && oldValue !== newValue) {
            this.#animate = true;
            this.#change(Number(newValue));
        }
    }

    public showModal(activeIndex = 0): void {
        super.showModal();

        this.activeIndex = activeIndex;
        this.#animate = false;
    }

    public close(): void {
        super.close();

        this.dispatchEvent(
            new CustomEvent(ImageViewerEvents.SELECTED_INDICATOR, {
                detail: this.activeIndex,
                bubbles: true
            })
        );
    }

    #renderIndicators = (): string => indicatorsTemplate.render({ slides: this.#slides });

    #renderControls = (): string => controlsTemplate.render({});

    #renderSlider = (): string => sliderTemplate.render({ slides: this.#slides });

    #renderTitle = (): void => {
        this.title = titleTemplate.render({
            current: this.activeIndex + 1,
            total: this.#slides.length
        });
    };

    #handleNextButtonClick = (): void => {
        this.activeIndex += 1;
        this.dispatchEvent(new Event(ImageViewerEvents.NEXT_BUTTON_CLICK));
    };

    #handlePrevButtonClick = (): void => {
        this.activeIndex -= 1;
        this.dispatchEvent(new Event(ImageViewerEvents.PREV_BUTTON_CLICK));
    };

    #handleKeyDown = (event: KeyboardEvent): void => {
        if (event.code === 'ArrowRight') {
            this.#nextButton?.focus();
            this.activeIndex++;
        }

        if (event.code === 'ArrowLeft') {
            this.#prevButton?.focus();
            this.activeIndex--;
        }
    };

    #connectElements(): void {
        this.#slidesCarousel = this.querySelector('nh-slides-carousel[data-role="images"]');
        this.#nextButton = this.querySelector('button[data-role="next"]');
        this.#prevButton = this.querySelector('button[data-role="prev"]');
        this.#indicators = this.querySelector('nh-carousel-indicators');
        this.#closeButtons = Array.from(
            this.querySelectorAll('button[data-role="image-viewer-close"]')
        );

        this.#nextButton?.addEventListener('click', this.#handleNextButtonClick);
        this.#prevButton?.addEventListener('click', this.#handlePrevButtonClick);
        this.#slidesCarousel?.addEventListener('changed', this.#handleSlideChanged);
        this.#slidesCarousel?.addEventListener('touchstart', this.#handleTouchStart, {
            passive: true
        });
        this.#slidesCarousel?.addEventListener('touchend', this.#handleTouchEnd);
        this.#slidesCarousel?.addEventListener(
            SlidesCarouselEvent.SLIDE_CHANGED,
            this.#slidesCarouselChangedEventHandler
        );

        for (const button of this.#closeButtons) {
            button.addEventListener('click', this.close.bind(this));
        }
    }

    #disconnectElements(): void {
        this.#nextButton?.removeEventListener('click', this.#handleNextButtonClick);
        this.#prevButton?.removeEventListener('click', this.#handlePrevButtonClick);
        this.#slidesCarousel?.removeEventListener('changed', this.#handleSlideChanged);
        this.#slidesCarousel?.removeEventListener('touchstart', this.#handleTouchStart);
        this.#slidesCarousel?.removeEventListener('touchend', this.#handleTouchEnd);
        this.#slidesCarousel?.removeEventListener(
            SlidesCarouselEvent.SLIDE_CHANGED,
            this.#slidesCarouselChangedEventHandler
        );

        for (const button of this.#closeButtons) {
            button.removeEventListener('click', this.close.bind(this));
        }
    }

    #dialogOpenedCallback(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        this.#render();
        this.#connectElements();
        this.#change(this.activeIndex);

        setTimeout((): void => {
            this.#slidesCarousel?.scrollToSlide(this.activeIndex, 0, this.#animate);
            this.element.focus({ preventScroll: true });
        }, 1);
    }

    #render(): void {
        if (this.#slides.length <= 0) {
            return;
        }

        this.#renderTitle();

        let html = this.#renderSlider();
        html += this.#renderControls();
        html += this.#renderIndicators();

        const content = document.createElement('div');
        content.classList.add('nh-image-viewer__content');
        content.innerHTML = html;

        this.content = content.outerHTML;
    }

    #change = (index: number) => {
        this.#renderTitle();
        this.#indicators?.dispatchEvent(
            new CustomEvent(CarouselIndicatorsEvents.SELECTED_INDICATOR, {
                detail: index
            })
        );

        this.#slidesCarousel?.scrollToSlide(index, 0, this.#animate);
    };

    #handleSelectedIndicator = (event: Event) => {
        if (event instanceof CustomEvent) {
            this.activeIndex = event.detail;
            event.preventDefault();
            event.stopPropagation();
        }
    };

    #handleSlideChanged = () => {
        if (this.#slidesCarousel?.slide === undefined) {
            return;
        }

        this.activeIndex = this.#slidesCarousel.slide;
        this.dispatchEvent(
            new CustomEvent(ImageViewerEvents.SLIDE_CHANGED, {
                detail: {
                    index: this.activeIndex
                }
            })
        );
    };

    #determineIndex(index: number): number {
        let newIndex = index;
        const last = this.#slides.length - 1;

        if (index > last) {
            newIndex = 0;
        } else if (index < 0) {
            newIndex = last;
        }

        return newIndex;
    }

    #handleTouchStart = (event: TouchEvent): void => {
        this.#touchStartX = event.touches[0].clientX;
    };

    #handleTouchEnd = (event: TouchEvent): void => {
        if (this.#touchStartX === event.changedTouches[0].clientX) {
            return;
        }

        if (this.#touchStartX < event.changedTouches[0].clientX) {
            this.dispatchEvent(new Event(ImageViewerEvents.PREV_SWIPE_ACTION));
            return;
        }

        this.dispatchEvent(new Event(ImageViewerEvents.NEXT_SWIPE_ACTION));
    };

    #slidesCarouselChangedEventHandler = (event: Event): void => {
        if (!(event instanceof CustomEvent)) {
            return;
        }

        this.dispatchEvent(new CustomEvent(ImageViewerEvents.SLIDE_CHANGED, event));
    };
}

if (!window.customElements.get('nh-image-viewer')) {
    window.customElements.define('nh-image-viewer', ImageViewer);
}
