var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/carousel-indicators/CarouselIndicators.macro.njk"] = require( "@naturehouse/design-system/components/atoms/carousel-indicators/CarouselIndicators.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/organisms/image-viewer/partials/_indicators.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/atoms/carousel-indicators/CarouselIndicators.macro.njk", false, "design-system/v3/components/organisms/image-viewer/partials/_indicators.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("carouselIndicatorsTemplate", t_1);
output += "\n\n";
var t_4;
t_4 = env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "slides"));
frame.set("indicatorsInView", t_4, true);
if(frame.topLevel) {
context.setVariable("indicatorsInView", t_4);
}
if(frame.topLevel) {
context.addExport("indicatorsInView", t_4);
}
output += "\n";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "slides")) > 5) {
output += "\n    ";
var t_5;
t_5 = 5;
frame.set("indicatorsInView", t_5, true);
if(frame.topLevel) {
context.setVariable("indicatorsInView", t_5);
}
if(frame.topLevel) {
context.addExport("indicatorsInView", t_5);
}
output += "\n";
;
}
output += "\n\n<div class=\"nh-image-viewer__indicators\">\n    ";
output += runtime.suppressValue((lineno = 8, colno = 40, runtime.callWrap(runtime.memberLookup((t_1),"render"), "carouselIndicatorsTemplate[\"render\"]", context, [{"indicators": env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "slides")),"indicatorsInView": runtime.contextOrFrameLookup(context, frame, "indicatorsInView"),"variant": "dark"}])), env.opts.autoescape);
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/organisms/image-viewer/partials/_indicators.html.njk"] , dependencies)