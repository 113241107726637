import { getCookieValue, setCookieValue } from '@naturehouse/nh-essentials/lib/cookies/cookie';

export default class RecentlyViewedHousesManager {
    readonly COOKIE_NAME: string = 'recently_viewed_houses';

    readonly MAX_RECENTLY_VIEWED: number = 6;

    static #instance: RecentlyViewedHousesManager | null = null;

    static getInstance(): RecentlyViewedHousesManager {
        if (this.#instance === null) {
            this.#instance = new RecentlyViewedHousesManager();
        }

        return this.#instance;
    }

    static destroyInstance(): void {
        this.#instance = null;
    }

    public constructor() {
        this.#updateRecentlyViewedHouses();
    }

    #updateRecentlyViewedHouses(): void {
        const houseID: string | null = this.#getHouseId();

        if (houseID === null) {
            return;
        }

        const cookieValue: string = getCookieValue(this.COOKIE_NAME) || '';
        const viewedHouses: string[] = cookieValue ? cookieValue.split(',') : [];

        if (viewedHouses.includes(houseID)) {
            viewedHouses.splice(viewedHouses.indexOf(houseID), 1);
        }

        if (viewedHouses.length >= this.MAX_RECENTLY_VIEWED) {
            viewedHouses.pop();
        }

        viewedHouses.unshift(houseID);

        setCookieValue(this.COOKIE_NAME, viewedHouses.join(','));
    }

    #getHouseId(): string | null {
        const element: HTMLMetaElement | null = document.getElementById(
            'house-id'
        ) as HTMLMetaElement | null;

        if (element === null || element.content === '') {
            return null;
        }

        return element.content;
    }
}
