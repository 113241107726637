import ListComponent, {
    ListItem,
    Orientation
} from '@naturehouse/design-system/components/molecules/list/List.create';

export class SustainabilityListRenderer {
    public generateListHtml(items: string[]): string {
        const listItems: ListItem[] = items.map(
            (item: string): ListItem => ({
                icon: { name: 'tree' },
                label: item
            })
        );

        return ListComponent({
            orientation: Orientation.VERTICAL,
            items: listItems
        });
    }
}

export default new SustainabilityListRenderer();
