import { Observer, Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import scrollToTarget from '@naturehouse/nh-essentials/lib/dom/scrollToTarget';
import ReviewListItems from './ReviewListItems';
import ReviewPaginationRenderer from './ReviewPaginationRenderer';

export default class ReviewPaginationHandler implements Observer {
    static #instance: ReviewPaginationHandler | null = null;

    static getInstance(): ReviewPaginationHandler {
        if (!ReviewPaginationHandler.#instance) {
            ReviewPaginationHandler.#instance = new ReviewPaginationHandler();
        }

        return ReviewPaginationHandler.#instance;
    }

    #paginationElement: HTMLElement | null = null;

    readonly #reviewPaginationRenderer: ReviewPaginationRenderer =
        ReviewPaginationRenderer.getInstance();

    readonly #reviewListItems: ReviewListItems = ReviewListItems.getInstance();

    public constructor() {
        this.#reviewPaginationRenderer.attach(this);
    }

    public update(subject: Subject): void {
        if (!(subject instanceof ReviewPaginationRenderer)) {
            return;
        }

        this.#paginationElement = subject.paginationElement;
        this.#setEventListeners();
    }

    #setEventListeners(): void {
        this.#paginationElement?.addEventListener('click', this.#handlePaginationClick);
    }

    readonly #handlePaginationClick = (event: Event): void => {
        event.preventDefault();

        if (!(event.target instanceof HTMLAnchorElement)) {
            return;
        }

        const url = new URL(event.target.href);
        const skip = Number(url.searchParams.get('skip')) || 0;

        this.#reviewListItems.skip = skip;

        const target = document.getElementById('reviews');
        if (target === null) {
            return;
        }

        scrollToTarget(target, 0, true);
    };
}
