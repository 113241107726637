import { Observer, Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import DescriptionCollectionStorage from './DescriptionCollectionStorage';
import NatureDescriptionCollectionStorage from './NatureDescriptionCollectionStorage';
import ReviewCollectionStorage from './ReviewCollectionStorage';
import { TranslatableContentCollection, TranslatableContentResponse } from './TranslatableContent';
import { iTranslationStorage } from './iTranslationStorage';

export type TranslationFetcherStorageType = {
    type: TranslatableContentCollection;
    id: string;
    currentContent?: string;
    currentLanguage?: string;
    sourceContent?: string;
    sourceLanguage?: string;
};

export default class TranslationFetcherStorage implements Observer {
    readonly #descriptionStorage = DescriptionCollectionStorage.getInstance();

    readonly #natureDescriptionStorage = NatureDescriptionCollectionStorage.getInstance();

    readonly #reviewStorage = ReviewCollectionStorage.getInstance();

    static #instance: TranslationFetcherStorage | null = null;

    public static getInstance(): TranslationFetcherStorage {
        if (this.#instance === null) {
            this.#instance = new TranslationFetcherStorage();
        }

        return this.#instance;
    }

    public static resetInstance(): TranslationFetcherStorage {
        this.#instance = new TranslationFetcherStorage();
        return this.#instance;
    }

    #translations: TranslationFetcherStorageType[] = [];

    get translations(): TranslationFetcherStorageType[] {
        return this.#translations;
    }

    set translations(value: TranslationFetcherStorageType[]) {
        this.#translations = value;
    }

    private constructor() {
        this.#descriptionStorage.attach(this);
        this.#natureDescriptionStorage.attach(this);
        this.#reviewStorage.attach(this);
    }

    public update(subject: Subject): void {
        if (!subject || !('translationData' in subject)) {
            return;
        }

        const sub = subject as iTranslationStorage;
        const data = sub.translationData;
        const type = subject.constructor.name as TranslatableContentCollection;

        if (!data) {
            return;
        }

        if (Array.isArray(data)) {
            data.forEach((item) => this.#storeTranslation(type, item));
            return;
        }

        this.#storeTranslation(type, data);
    }

    #storeTranslation(
        type: TranslatableContentCollection,
        data: TranslatableContentResponse
    ): void {
        const index = this.#getIndex(type, data.id.toString());
        if (index === -1) {
            return;
        }

        this.translations[index] = {
            ...this.translations[index],
            ...{
                sourceContent: data.text,
                sourceLanguage: data.language
            }
        };
    }

    public add(translation: TranslationFetcherStorageType): void {
        if (this.#getIndex(translation.type, translation.id) !== -1) {
            return;
        }

        this.translations.push(translation);
    }

    public get(
        type: TranslatableContentCollection,
        id: string
    ): TranslationFetcherStorageType | undefined {
        return this.translations.find(
            (translation) => type === translation.type && id === translation.id
        );
    }

    public remove(type: TranslatableContentCollection, id: string): void {
        this.translations = this.translations.filter(
            (translation) => translation.id !== id || translation.type !== type
        );
    }

    readonly #getIndex = (type: TranslatableContentCollection, id: string): number =>
        this.translations.findIndex(
            (translation) => type === translation.type && id === translation.id
        );
}
