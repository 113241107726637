import '../../../../src/components/protons/icon/Icon';
import type { IconProps } from '../../../../src/components/protons/icon/Icon';
import type { DefaultComponentType } from '../../../../src/utils/types';
import './Input.pcss';

export enum HTMLInputType {
    BUTTON = 'button',
    CHECKBOX = 'checkbox',
    COLOR = 'color',
    DATE = 'date',
    DATETIME_LOCAL = 'datetime-local',
    EMAIL = 'email',
    FILE = 'file',
    HIDDEN = 'hidden',
    IMAGE = 'image',
    MONTH = 'month',
    NUMBER = 'number',
    PASSWORD = 'password',
    RADIO = 'radio',
    RANGE = 'range',
    RESET = 'reset',
    SEARCH = 'search',
    SUBMIT = 'submit',
    TEL = 'tel',
    TEXT = 'text',
    TIME = 'time',
    URL = 'url',
    WEEK = 'week'
}

export type InputProps = DefaultComponentType & {
    id?: string;
    autocomplete?: string;
    autofocus?: string;
    checked?: boolean;
    disabled?: boolean;
    errorText?: string;
    form?: string;
    helpText?: string;
    icon?: IconProps;
    isToggle?: boolean;
    label?: string;
    max?: number;
    min?: number;
    maxlength?: number;
    minlength?: number;
    minimal?: boolean;
    multiple?: boolean;
    name?: string;
    onClear?: (event: Event) => void;
    onChange?: (event: Event) => void;
    onFocus?: (event: Event) => void;
    onBlur?: (event: Event) => void;
    pattern?: string;
    placeholder?: string;
    readonly?: boolean;
    required?: boolean;
    size?: number;
    step?: number;
    type: HTMLInputType;
    value?: string;
};

export default class Input extends HTMLInputElement {
    #isDisabled: boolean = this.hasAttribute('aria-disabled');

    get isDisabled(): boolean {
        return this.#isDisabled;
    }

    set isDisabled(value: boolean) {
        this.#isDisabled = value;
        this.toggleAttribute('aria-disabled', value);
        this.toggleAttribute('readonly', value);
        this.dispatchEvent(new Event('disabled'));

        if (this.type === HTMLInputType.CHECKBOX || this.type === HTMLInputType.RADIO) {
            this.toggleAttribute('disabled', value);
        }
    }

    constructor() {
        super();

        this.classList.add('nh-input-new');
    }

    public validate(): boolean {
        const isValid = this.validity.valid;

        if (isValid === false) {
            this.dispatchEvent(new Event('invalid'));
        }

        return isValid;
    }

    protected connectedCallback(): void {
        this.addEventListener('clear', this.#onClear);
        this.addEventListener('blur', this.#onBlur);
    }

    protected disconnectedCallback(): void {
        this.removeEventListener('clear', this.#onClear);
        this.removeEventListener('blur', this.#onBlur);
    }

    #onClear(event: Event): void {
        if (this.#isDisabled) {
            event.preventDefault();
            return;
        }

        this.value = '';
        this.focus();
        this.dispatchEvent(new CustomEvent('cleared'));
    }

    #onBlur(): void {
        if (this.#isDisabled) {
            return;
        }

        this.validate();
    }
}

if (!window.customElements.get('nh-input')) {
    window.customElements.define('nh-input', Input, { extends: 'input' });
}
