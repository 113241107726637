import {
    FORMATS,
    intlDateFormat,
    intlDatePlaceholder
} from '@naturehouse/nh-essentials/lib/dates/intlDateTimeFormatter';
import '../../../../src/components/protons/icon/Icon';
import '../input/Input';
import type { DefaultComponentType } from '../../../../src/utils/types';
import template from './DateFromTo.html.njk';
import './DateFromTo.pcss';

export type DateRange = {
    start?: string;
    end?: string;
};

export type DateFromToProps = DefaultComponentType &
    DateRange & {
        disabled?: boolean;
        startPlaceholder?: string;
        endPlaceholder?: string;
    };

export default class DateFromTo extends HTMLElement {
    #localizedDatePlaceholder: string = intlDatePlaceholder();

    #isDisabled: boolean = this.hasAttribute('aria-disabled');

    #startValueElement: Element | null = null;

    #endValueElement: Element | null = null;

    get isDisabled(): boolean {
        return this.#isDisabled;
    }

    set isDisabled(value: boolean) {
        this.#isDisabled = value;
        this.toggleAttribute('aria-disabled', value);
        this.toggleAttribute('readonly', value);
    }

    static get observedAttributes(): string[] {
        return ['start', 'end', 'startplaceholder', 'endplaceholder', 'disabled'];
    }

    get validity(): ValidityState {
        return {
            badInput: false,
            customError: false,
            patternMismatch: false,
            rangeOverflow: false,
            rangeUnderflow: false,
            stepMismatch: false,
            tooLong: false,
            tooShort: false,
            typeMismatch: false,
            valueMissing: false,
            valid: true
        };
    }

    get value(): string {
        const start: string = this.#start;
        const end: string = this.#end;

        if (!start && !end) {
            return '';
        }

        return JSON.stringify({ start: this.#start, end: this.#end });
    }

    set value(value: string) {
        if (value === '') {
            this.removeAttribute('start');
            this.removeAttribute('end');
            return;
        }

        const range: DateRange = JSON.parse(value);
        this.setAttribute('start', range?.start || '');
        this.setAttribute('end', range?.end || '');
    }

    constructor(props?: DateFromToProps) {
        super();

        this.#init(props);
        this.attachShadow({ mode: 'open' });

        this.addEventListener('clear', this.#onClear);
    }

    protected attributeChangedCallback(name: string): void {
        if (name === 'disabled') {
            this.isDisabled = this.hasAttribute('disabled');
            this.#updateInputs();
        }
        this.#render();

        if (name === 'start' || name === 'end') {
            this.#updateInputs();
            this.dispatchEvent(new Event('change'));
        }
    }

    protected connectedCallback(): void {
        this.#isDisabled = this.hasAttribute('aria-disabled');
        this.#render();
    }

    #onClear(event: Event): void {
        if (this.#isDisabled) {
            event.preventDefault();
            return;
        }

        this.value = '';
        this.focus();
        this.dispatchEvent(new CustomEvent('cleared'));
    }

    #init(props?: DateFromToProps): void {
        const start: string = props?.start || this.#start;
        const startPlaceholder: string = props?.startPlaceholder || this.#startPlaceholder;

        const end: string = props?.end || this.#end;
        const endPlaceholder: string = props?.endPlaceholder || this.#endPlaceholder;

        if (props?.classes !== undefined) {
            props.classes.forEach((item: string): void => {
                this.classList.add(item);
            });
        }

        if (props?.data !== undefined) {
            const data: Record<string, string> = props.data;
            Object.keys(data).forEach((key: string): void => {
                this.dataset[key] = data[key];
            });
        }

        this.setAttribute('start', start);
        this.setAttribute('startplaceholder', startPlaceholder);

        this.setAttribute('end', end);
        this.setAttribute('endplaceholder', endPlaceholder);

        this.setAttribute('tabindex', this.getAttribute('tabindex') || '0');
    }

    get #start(): string {
        return this.getAttribute('start') || '';
    }

    get #end(): string {
        return this.getAttribute('end') || '';
    }

    get #startPlaceholder(): string {
        return this.getAttribute('startplaceholder') || this.#localizedDatePlaceholder;
    }

    get #endPlaceholder(): string {
        return this.getAttribute('endplaceholder') || this.#localizedDatePlaceholder;
    }

    #render(): void {
        if (!this.shadowRoot) {
            return;
        }

        let start: string = '';
        let end: string = '';

        try {
            start = this.#start !== '' ? intlDateFormat(this.#start, FORMATS.SHORT) : '';
            end = this.#end !== '' ? intlDateFormat(this.#end, FORMATS.SHORT) : '';
        } catch (e) {
            // stub
        }

        const startPlaceholder: string = this.#startPlaceholder;
        const endPlaceholder: string = this.#endPlaceholder;

        this.shadowRoot.innerHTML = template.render({
            start,
            end,
            startPlaceholder,
            endPlaceholder
        });

        this.#startValueElement = this.shadowRoot.querySelector('[part="start-value"]');
        this.#endValueElement = this.shadowRoot.querySelector('[part="end-value"]');

        this.#startValueElement?.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('select', { detail: 'start' }));
        });
        this.#endValueElement?.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('select', { detail: 'end' }));
        });
    }

    #updateInputs(): void {
        ['start', 'end'].forEach((fieldName: string): void => {
            const value: string | null = this.getAttribute(fieldName);
            const input: HTMLInputElement | null = this.querySelector(
                `[data-input="${fieldName}"]`
            );

            if (input === null || value === null) {
                return;
            }

            input.value = value;
            input.disabled = this.isDisabled;
        });
    }
}

if (!window.customElements.get('nh-date-from-to')) {
    window.customElements.define('nh-date-from-to', DateFromTo);
}
