import UnavailableMethodException from '@naturehouse/nh-essentials/lib/exceptions/UnavailableMethodException';
import HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';
import CollectionBase from '../../../../../common/CollectionBase';

export type ArrivalDatesCollectionApiData = {
    data: string[];
};

export type ArrivalDatesCollectionEndpointParams = {
    houseId: string;
    searchParams: URLSearchParams;
};

export default class ArrivalDatesCollection extends CollectionBase<
    ArrivalDatesCollectionApiData,
    ArrivalDatesCollectionEndpointParams
> {
    static #instance: ArrivalDatesCollection | null = null;

    public static getInstance(): ArrivalDatesCollection {
        if (this.#instance === null) {
            this.#instance = new ArrivalDatesCollection();
        }

        return this.#instance;
    }

    public async add(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public retrieve<RT = string[]>(data: ArrivalDatesCollectionEndpointParams): Promise<RT> {
        return super.retrieve<string>(data) as Promise<RT>;
    }

    public async delete(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async update(): Promise<void> {
        throw new UnavailableMethodException();
    }

    protected endpoint(params: ArrivalDatesCollectionEndpointParams): string {
        if (
            !params.houseId ||
            !params.searchParams.get('start_date') ||
            !params.searchParams.get('end_date')
        ) {
            throw new Error('Either houseId, start_date or end_date is missing.');
        }

        return `/api/houses/${params.houseId}/arrival-dates?${params.searchParams}`;
    }

    protected onError(error: HttpError<AjaxResponseError>): void {
        throw new Error(error.body?.message);
    }
}
