import { NotificationType } from '@naturehouse/design-system/components/atoms/notification/Notification';
import Dialog from '@naturehouse/design-system/components/molecules/dialog/Dialog';
import { matchMediaAddEventListener } from '@naturehouse/nh-essentials/lib/polyfills/matchMedia';
import GoogleAnalyticsEvent from '../../../common/GoogleAnalyticsEvents';
import TranslationManager from '../../../common/TranslationManager';
import createNotification from '../../../util/notification';
import HouseDetailTrackingEvent from '../HouseDetailTracking';
import SustainabilityCollection from './SustainabilityCollection';
import SustainabilityDialogRenderer from './SustainabilityDialogRenderer';
import SustainabilityListRenderer from './SustainabilityListRenderer';
import SustainabilityLoaderRenderer from './SustainabilityLoaderRenderer';

export default class SustainabilityDialogManager {
    readonly #houseId: string;

    readonly #sustainabilityCollection: SustainabilityCollection;

    #sustainabilityDialog: Dialog | null = null;

    set sustainabilityDialog(sustainabilityDialog: Dialog | null) {
        if (this.#sustainabilityDialog) {
            document.body.removeChild(this.#sustainabilityDialog);
        }

        this.#sustainabilityDialog = sustainabilityDialog;

        if (this.#sustainabilityDialog === null) {
            return;
        }

        document.body.appendChild(this.#sustainabilityDialog);
    }

    public constructor(houseId: string) {
        this.#houseId = houseId;
        this.#sustainabilityCollection = SustainabilityCollection.getInstance();
    }

    public async initialize(): Promise<void> {
        this.sustainabilityDialog = await SustainabilityDialogRenderer.render();
        this.#setupButtonClick();
    }

    #setupButtonClick(): void {
        const openSustainabilityDialogButton: HTMLElement | null = document.querySelector(
            '[data-role="open-sustainability-modal"]'
        );

        if (!openSustainabilityDialogButton) {
            return;
        }

        openSustainabilityDialogButton.addEventListener('click', this.#handleButtonClick);
        const mq: MediaQueryList = window.matchMedia('(min-width: 1024px)');

        matchMediaAddEventListener(mq, (e) => {
            if (e.matches && this.#sustainabilityDialog) {
                this.#sustainabilityDialog.close();
            }
        });
    }

    readonly #handleButtonClick = async (): Promise<void> => {
        GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.OPEN_SUSTAINABILITY_BUTTON_CLICK);

        if (!this.#sustainabilityDialog) {
            return;
        }

        this.#sustainabilityDialog.content =
            SustainabilityLoaderRenderer.generateSkeletonLoaderHtml(4);
        this.#sustainabilityDialog.showModal();

        try {
            const results = await this.#sustainabilityCollection.retrieve({
                houseId: this.#houseId
            });

            if (!results || results.length === 0) {
                return;
            }

            this.#sustainabilityDialog.content =
                SustainabilityListRenderer.generateListHtml(results);
        } catch {
            this.#sustainabilityDialog.close();
            const tm = await TranslationManager.getInstance();
            createNotification(NotificationType.ERROR, tm.translate('error.label'));
        }
    };
}
