import AbstractSubject from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import ReviewCollection, { ReviewCollectionResponse, ReviewItem } from './ReviewCollection';

export type InitialState = {
    reviews: ReviewItem[];
    total: number;
    limit: number;
};

export default class ReviewListItems extends AbstractSubject {
    static #instance: ReviewListItems | null = null;

    #reviews: ReviewItem[] = [];

    public static getInstance(
        initialState: InitialState = {
            reviews: [],
            total: 0,
            limit: 3
        }
    ): ReviewListItems {
        if (ReviewListItems.#instance === null) {
            ReviewListItems.#instance = new ReviewListItems();

            ReviewListItems.#instance.#reviews = initialState.reviews;
            ReviewListItems.#instance.#total = initialState.total;
        }

        return ReviewListItems.#instance;
    }

    readonly #collection = ReviewCollection.getInstance();

    #houseId: string | null = null;

    set houseId(value: string | null) {
        this.#houseId = value;
    }

    get reviews(): ReviewItem[] {
        return this.#reviews;
    }

    #total = 0;

    get total(): number {
        return this.#total;
    }

    #limit = 3;

    get limit(): number {
        return this.#limit;
    }

    #skip = 0;

    get skip(): number {
        return this.#skip;
    }

    set skip(value: number) {
        this.#skip = value;
        this.fetchReviews();
    }

    public readonly fetchReviews = async (): Promise<ReviewItem[]> => {
        if (!this.#houseId) {
            throw new Error('houseId is not set');
        }

        const reviewResponse: ReviewCollectionResponse = await this.#collection.retrieve({
            houseId: this.#houseId,
            skip: this.#skip
        });

        this.setResults(
            reviewResponse.reviews,
            reviewResponse.total,
            reviewResponse.limit,
            reviewResponse.skip
        );

        return reviewResponse.reviews;
    };

    public setResults(reviews: ReviewItem[], total: number, limit: number, skip: number): void {
        this.#reviews = reviews;
        this.#total = total;
        this.#limit = limit;
        this.#skip = skip;
        this.notify();
    }
}
