import './ImpactHouseTag.pcss';
import Icon from '../../protons/icon/Icon';

export enum ImpactLevel {
    LEVEL_1 = 1,
    LEVEL_2 = 2,
    LEVEL_3 = 3
}

export type ImpactHouseTagProps = {
    content: string;
    percentage: number;
};

export default class ImpactHouseTag extends HTMLElement {
    #level: ImpactLevel | null = null;

    get percentage(): number | null {
        return this.getAttribute('percentage') === null
            ? null
            : Number(this.getAttribute('percentage'));
    }

    set percentage(value: number | null) {
        if (value !== null) {
            this.setAttribute('percentage', value.toString());
            return;
        }

        this.removeAttribute('percentage');
    }

    get content(): string | null {
        const content = this.getAttribute('content');
        if (content) {
            return content;
        }

        if (this.#contentSlot && this.#contentSlot.innerHTML) {
            return this.#contentSlot.innerHTML;
        }

        return null;
    }

    set content(value: string | null) {
        if (value !== null) {
            this.setAttribute('content', value);
            this.#renderContent(value);
            return;
        }

        this.removeAttribute('content');
        this.#renderContent(null);
    }

    #iconSlot: HTMLElement | null = this.querySelector('[slot="icon"]');

    #contentSlot: HTMLElement | null = this.querySelector('[slot="content"]');

    public constructor(passedInProps?: ImpactHouseTagProps) {
        super();

        const percentage = passedInProps?.percentage ?? this.percentage;
        const content = passedInProps?.content ?? this.content;

        if (percentage === null || content === null) {
            return;
        }

        this.#setLevelBasedOnPercentage(percentage);
        if (this.#level === null) {
            this.remove();
            return;
        }

        this.render(this.#level, content);
    }

    public render(level: ImpactLevel, content: string): void {
        this.#renderIcon(level);
        this.#renderContent(content);
    }

    #renderIcon(level: ImpactLevel | null): void {
        if (this.#iconSlot === null) {
            this.#iconSlot = document.createElement('div');
            this.#iconSlot.setAttribute('slot', 'icon');
            this.append(this.#iconSlot);
        }

        const icon: Icon = new Icon();
        icon.name = `impact-level-${level}`;
        this.#iconSlot.innerHTML = icon.outerHTML;
    }

    #renderContent(content: string | null): void {
        if (this.#contentSlot === null) {
            this.#contentSlot = document.createElement('p');
            this.#contentSlot.setAttribute('slot', 'content');
            this.append(this.#contentSlot);
        }

        this.#contentSlot.innerHTML = content ?? '';
    }

    #setLevelBasedOnPercentage(percentage: number): void {
        if (percentage >= 40 && percentage < 60) {
            this.#level = ImpactLevel.LEVEL_1;
            return;
        }

        if (percentage >= 60 && percentage < 80) {
            this.#level = ImpactLevel.LEVEL_2;
            return;
        }

        if (percentage >= 80) {
            this.#level = ImpactLevel.LEVEL_3;
            return;
        }

        this.#level = null;
    }
}

if (!customElements.get('nh-impact-house-tag')) {
    customElements.define('nh-impact-house-tag', ImpactHouseTag);
}
