var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/carousel-indicators/CarouselIndicators.html.njk"] = require( "@naturehouse/design-system/components/atoms/carousel-indicators/CarouselIndicators.html.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/carousel-indicators/CarouselIndicators.macro.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["props"], 
[], 
function (l_props, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("props", l_props);
var t_2 = "";t_2 += "\n    ";
var t_3;
t_3 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"indicators"),0);
frame.set("indicators", t_3, true);
if(frame.topLevel) {
context.setVariable("indicators", t_3);
}
if(frame.topLevel) {
context.addExport("indicators", t_3);
}
t_2 += "\n    ";
var t_4;
t_4 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"indicatorsInView"),5);
frame.set("indicatorsInView", t_4, true);
if(frame.topLevel) {
context.setVariable("indicatorsInView", t_4);
}
if(frame.topLevel) {
context.addExport("indicatorsInView", t_4);
}
t_2 += "\n    ";
var t_5;
t_5 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"activeIndex"),0);
frame.set("activeIndex", t_5, true);
if(frame.topLevel) {
context.setVariable("activeIndex", t_5);
}
if(frame.topLevel) {
context.addExport("activeIndex", t_5);
}
t_2 += "\n    ";
var t_6;
t_6 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"variant"),"dark");
frame.set("variant", t_6, true);
if(frame.topLevel) {
context.setVariable("variant", t_6);
}
if(frame.topLevel) {
context.addExport("variant", t_6);
}
t_2 += "\n\n    ";
var t_7;
t_7 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"classes"),[]);
frame.set("classes", t_7, true);
if(frame.topLevel) {
context.setVariable("classes", t_7);
}
if(frame.topLevel) {
context.addExport("classes", t_7);
}
t_2 += "\n    ";
var t_8;
t_8 = env.getFilter("default").call(context, runtime.memberLookup((l_props),"data"),{});
frame.set("data", t_8, true);
if(frame.topLevel) {
context.setVariable("data", t_8);
}
if(frame.topLevel) {
context.addExport("data", t_8);
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/atoms/carousel-indicators/CarouselIndicators.html.njk", false, "design-system/v3/components/atoms/carousel-indicators/CarouselIndicators.macro.njk", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
callback(null,t_9);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
callback(null,t_11);});
});
tasks.push(
function(result, callback){
t_2 += result;
callback(null);
});
env.waterfall(tasks, function(){
t_2 += "\n";
});
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("render");
context.setVariable("render", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/carousel-indicators/CarouselIndicators.macro.njk"] , dependencies)